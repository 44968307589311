import { Text, Html, ContactShadows, PresentationControls, Float, Environment, useGLTF, Center } from '@react-three/drei'

export default function Experience()
{
    
    const computer = useGLTF('./PCDesktopScene.glb')
    
    return <>

        <Environment preset='city' />

        <color args={ ['#241a1a'] } attach="background" />

        <PresentationControls
            global
            rotation={ [ 0.13, 0.1, 0 ] }
            polar={ [ -0.4, 0.2 ] }
            azimuth={ [ - 1, 0.75 ] }
            config={ { mass: 2, tension: 400 } }
            snap={ { mass: 4, tension: 400 } }
        >
            <Float rotationIntensity={ 0.4 } >
                <rectAreaLight 
                    width={ 2.5 }
                    height={ 1.65 }
                    intensity={ 65 }
                    color={ '#6935ff' }
                    rotation={ [ 0.1, Math.PI, 0  ] }
                    position={ [ 0, 0.55, -1.15 ] }
                    textAlign="center"

                
                />

                <primitive object={ computer.scene } 
                    scale={0.28}
                    rotation-y={-2.1}
                >
                    <Html  
                        transform
                        wrapperClass= "htmlScreen"
                        distanceFactor={ 9.5 }
                        position={ [ -1.5, 3, 3.9 ] }
                        rotation={ [ 0.005, 1.55, 0 ] }
                        scale={ [ 0.9, 0.95, 1 ] }


                    >
                        <iframe 
                            src="https://www.madygnome.com.co/"
                            
                        /> 
                    </Html>
                </primitive>
                <Text
                    font="./bangers-v20-latin-regular.woff"
                    fontSize={ 1 }
                    position={ [ 3, 0.75, 0.75 ] }
                    rotation-y={ -1.25 }
                    maxWidth={ 2 }
                > 
                    CAMILO ADAMS 
                </Text>
                <Center />
            </Float>
        </PresentationControls>

        <ContactShadows 
            position-y={ - 1.4 }
            opacity={ 0.4 }
            scale={ 5 }
            blur={ 2.4 } 
        />


        
        

    </>
}